<template>
    <div class="px-3 md:px-0 pt-4">
        
        <div class="header bg-yellow-500 rounded-md px-4 py-3" v-if="subKategori != null">
            <div class="flex items-center">
                <div class="text-white mr-2 cursor-pointer" @click="$router.go(-1)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                </div>
                <div>
                    <h4 class="text-white font-semibold tracking-wide text-sm">{{ subKategori.kategori.judul }}</h4>
                    <p class=" font-semibold tracking-tight text-white sm:text-2xl">
                        {{ subKategori.judul }}
                    </p>
                </div>
            </div>
        </div>

        <p class="my-4 text-xl leading-8 font-semibold tracking-tight text-gray-900 capitalize">
            Daftar Materi
        </p>
        
        <div class="animate-pulse" v-if="isLoadingMateri">
            <div class="flex bg-white py-2 px-3 my-2 transition-colors" v-for="i in 3" :key="i">
                <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                </div>
                <div class="flex-grow ml-3 pt-1 pb-1">
                    <div class="h-6 bg-gray-100 rounded"></div>
                    <div class="h-5 w-32 mt-2 inline-block mr-2 bg-gray-100 rounded"></div>
                    <div class="h-5 w-40 mt-2 inline-block bg-gray-100 rounded"></div>
                </div>
            </div>
        </div>

        
        <ol class="relative border-l border-gray-200 dark:border-gray-700 ml-5" v-if="!isLoadingMateri">
            <li class="mb-10 ml-10" v-for="(data, index) in materiList" :key="index">

                <span class="flex absolute -left-5 justify-center items-center w-10 h-10 rounded-full" :class="data.is_locked == 1 ? 'bg-gray-200' : 'bg-blue-200'">
                    <svg class="w-5 h-5" :class="data.is_locked == 1 ? 'text-gray-600' : 'text-blue-600' " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                </span>

                <h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                    {{ data.judul_materi }}
                    <span v-if="data.is_locked == 1" class="bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ml-3">Locked</span>
                </h3>

                <time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">Publish pada {{ data.created_at | toHumanDate2 }}</time>
                <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">{{ data.deskripsi }}</p>

                <a href="javascript:void(0)" @click="loadDaftarKontenMateri(data.id_materi)" class="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 ring-2">
                    
                    <svg v-if="(data.id_materi == active_materi_id && isLoadingKontenMateri == true)" class="animate-spin inline-block h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>

                    Daftar Tasks
                </a>

                <div class="px-4 py-3 mt-4 bg-gray-100 rounded-lg mb-4" v-if="data.id_materi == active_materi_id && materiDetail != null">
                    <h2 class="font-semibold text-lg text-gray-900">Daftar Isi Materi</h2>

                    <div @click="gotoKonten(konten)" class="flex cursor-pointer items-center py-2" v-for="(konten, ind) in materiDetail.konten" :key="ind">
                        <div class="h-10 w-10 bg-yellow-300 rounded-lg flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto text-yellow-500" v-if="konten.jenis_konten == 'materi'" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto text-yellow-500" v-if="konten.jenis_konten == 'kuis'" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div class="flex-wrap text-gray-800 ml-3 flex-grow">
                            <div class="font-semibold">{{ konten.judul_konten }}</div>
                            <span class="text-gray-500">{{ konten.created_at | toHumanDate3 }}</span>
                        </div>
                        <div v-if="konten.history != null && konten.history.passing == 1">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div v-else-if="konten.history != null && konten.history.passing == 0">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div v-else>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                            </svg>
                        </div>
                    </div>

                    <button @click="gotoKonten(materiDetail.next_konten)" v-if="materiDetail.next_konten != null" class="mt-2 block w-full md:inline-block md:w-auto px-3 py-2 md:py-2 bg-blue-500 text-white rounded-lg font-semibold text-sm">
                        <svg v-if="isLoadingLanjutMateri" class="animate-spin ml-1 mr-3 h-5 w-5 text-blue-700 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Mulai Pelajari Materi
                    </button>

                </div>

            </li>
        </ol>


        <div v-if="!isLoadingMateri && materiList.length == 0" class="text-center w-full mt-6" >
            <div class="w-2/3 px-5 mx-auto">
                <img src="@/assets/images/cloud-computing.svg" class="w-40 mx-auto" />
                <p class="mt-1 text-gray-800 font-semibold">Maaf, data masih kosong</p>
            </div>
        </div>

   </div>
</template>
<script>
import { errorHandler, showErrorNotif } from '../../helpers/Tools';
    export default {
        name: 'MateriSubKategoriList',
        data(){
            return {
                isLoading            : true,
                isLoadingMateri      : true,
                subKategori          : null,
                materiList           : [],
                materiDetail         : null,
                active_materi_id     : 0,
                isLoadingKontenMateri: false,
                isLoadingLanjutMateri: false,
                isMateriDetailLoaded : false,

            }
        },
        mounted() {
			this.getSubKategoriDetail();
		},
		methods : {
            async getSubKategoriDetail() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getSubKategoriDetail", this.$route.params.singkatan_sub_kategori)
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.subKategori = result.data;
                        this.getDaftarMateriPerSubKategori();
                    } else {
                        showErrorNotif(this, result.data);
                    }
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

            async getDaftarMateriPerSubKategori() {
                this.isLoadingMateri = true;
                try {
                    let res = await this.$store.dispatch("getDaftarMateriPerSubKategori", {
                        id_kategori    : this.subKategori.id_kategori,
                        id_sub_kategori: this.subKategori.id_sub_kategori,
                    })
                    let result = res.data
                    this.isLoadingMateri = false;
                    if(result.status == 'success') {
                        this.materiList = result.data;
                    } else {
                        showErrorNotif(this, result.data);
                    }
                } catch (error) {
                    this.isLoadingMateri = false;
                    errorHandler(this, error);
                }
            },

            async loadDaftarKontenMateri (id_materi) {

                if(this.active_materi_id == id_materi) {
                    this.active_materi_id = null
                } else {
                    this.isMateriDetailLoaded  = false;
                    this.active_materi_id      = id_materi;
                    this.isLoadingKontenMateri = true;
                    this.materiDetail          = null;

                    try {
                        let res = await this.$store.dispatch("getKontenMateriPerMateri", id_materi)
                        let result = res.data
                        this.isLoadingKontenMateri = false;
                        if(result.status == 'success') {
                            this.materiDetail = result.data;
                            this.isMateriDetailLoaded = true;
                        } else {
                            this.isMateriDetailLoaded = false;
                            showErrorNotif(this, result.data);
                        }
                    } catch (error) {
                        this.isMateriDetailLoaded  = false;
                        this.isLoadingKontenMateri = false;
                        errorHandler(this, error);
                    }
                }

            },

            gotoKonten(materiKonten) {
                this.$router.push({ name: 'ViewMateriKonten', params: { 
                    singkatan_kategori    : this.subKategori.kategori.singkatan.toLowerCase(),
                    singkatan_sub_kategori: this.subKategori.singkatan.toLowerCase(),
                    id_materi             : materiKonten.id_materi,
                    id_materi_konten      : materiKonten.id_materi_konten
                }});
            }

        }
    }
</script>
