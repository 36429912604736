export function showSuccessNotif(vm, message) {
    vm.$store.dispatch('actionShowNotification', {
        'title': 'Berhasil',
        'message': message,
        'type': 'success'
    });

    return new Promise(resolve => {
        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            vm.$store.dispatch('actionShowNotification', null);
            resolve();
        }, 3000);
    });
}

export function showErrorNotif(vm, message = 'Maaf, terjadi kesalahan jaringan') {
    vm.$store.dispatch('actionShowNotification', {
        'title'  : 'Perhatian',
        'message': message,
        'type'   : 'warning'
    });
    return new Promise(resolve => {
        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            vm.$store.dispatch('actionShowNotification', null);
            resolve();
        }, 3000);
    });
}

export function showWarningNotif(vm, message) {
    vm.$store.dispatch('actionShowNotification', {
        'title'  : 'Gagal',
        'message': message,
        'type'   : 'warning'
    });
    return new Promise(resolve => {
        const timeout = setTimeout(() => {
            clearTimeout(timeout);
            vm.$store.dispatch('actionShowNotification', null);
            resolve();
        }, 3000);
    });
}


export function extractValidationError(errorObject, apiResult) {
    errorObject = {};
    for (const k in apiResult) {
        const errItem = apiResult[k]
        errorObject[k] = errItem.join(', ')
    }
    return errorObject
}


export function errorHandler(vm, error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
        showWarningNotif(vm, "Silahkan login untuk melanjutkan");
        localStorage.clear();
        vm.$router.push('/login')
    } else {
        vm.$store.dispatch("actionOnError", true)
    }
}

export function isMobile() {

    if (typeof window.flutter_inappwebview != 'undefined') {
        return true
    } else {
        return false
    }
        
    // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     return true
    // } else {
    //     return false
    // }
}

export function copyToClipboard(element, string) {
    element.$copyText(string).then(function (e) {
        showSuccessNotif(element, "Teks berhasil disalin")
    }, function (e) {
        showErrorNotif(element, "Teks gagal disalin")
    })
}

export function combineObject(currentData, newData, keyName) {
    var ids = new Set(currentData.map(d => d[keyName]));
    var merged = [...currentData, ...newData.filter(d => !ids.has(d[keyName]))];
    return merged;
}